import React, { useState, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import {
  Scheduler,
  MonthView,
  Appointments,
  AppointmentTooltip,
} from '@devexpress/dx-react-scheduler-material-ui'
import { ViewState } from '@devexpress/dx-react-scheduler'
import { Paper } from '@material-ui/core'

import Layout from '../../components/layout/layout'
import SEO from '../../components/seo/seo'

const getMonthStreams = () => {
  const streams = []
  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth()

  const start = new Date(currentYear, currentMonth, 0)
  const end = new Date(currentYear, currentMonth + 1, 0)

  for (let dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
    const getStreamType = {
      0: () => [],
      1: () => {
        return [
          {
            startDate: new Date(dt).setTime(new Date(dt).setHours(0)),
            endDate: new Date(dt).setTime(new Date(dt).setHours(1)),
            title: `Live Coding`,
          },
        ]
      },
      2: () => [],
      3: () => [],
      4: () => [],
      5: () => [],
      6: () => [],
      7: () => [],
    }
    streams.push(...getStreamType[dt.getDay()]())
  }
  console.log(streams)
  return streams
}

export const LivePage = ({
  data: {
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState('it')
  const subtitle = `Vieni a guardare qualche contenuto interessante completamente gratuito nelle mie live stream!`
  const data = [
    {
      startDate: new Date().setTime(new Date().setHours(0)),
      endDate: new Date().setTime(new Date().setHours(1)),
      title: `Live Coding`,
    },
  ]

  useEffect(() => {
    if (selectedLanguage === 'en') {
      navigate('/live/')
    }
  }, [selectedLanguage])

  return (
    <Layout
      location='/it/live'
      title={title}
      defaultLang='IT'
      setSelectedLanguage={setSelectedLanguage}
      isIndex={true}
      subtitle={subtitle}
    >
      <SEO
        title={title}
        keywords={['blog', 'tags', 'page', 'twitch', 'mixer']}
      />
      <Paper>
        <Scheduler data={getMonthStreams()}>
          <ViewState currentDate={new Date()} />
          <MonthView />
          <Appointments />
          <AppointmentTooltip />
        </Scheduler>
      </Paper>
    </Layout>
  )
}

export default LivePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
